@media screen and (width >= 1919px) {
  body .container, body .about_container {
    width: 57%;
  }

  body .container .content_header, body .about_container .content_header {
    width: 65%;
  }

  body .container .content_header .content p, body .about_container .content_header .content p {
    font-size: 1.1rem;
  }

  body .container main, body .about_container main {
    width: 100%;
  }

  body .container main .pick_up_section .flex p, body .container main .delivery_section .flex p, body .about_container main .pick_up_section .flex p, body .about_container main .delivery_section .flex p {
    font-size: 1.1rem;
  }

  body .container nav .logo h4, body .about_container nav .logo h4 {
    font-size: 1rem;
  }

  body .banner {
    height: max(350px, 30vh);
  }

  body .banner .container .banner_content .banner_caption div p {
    font-size: clamp(1rem, .85rem + .6667vw, 1rem);
  }

  body .banner .container .banner_content .banner_caption div a .about_us {
    font-size: .9rem;
  }

  body .maps {
    height: 20vh;
  }

  body .about_description .about_textholder .about_text p {
    color: #474747;
    font-size: clamp(.5rem, 1vw, 1rem);
  }

  body .about_description2 .about_textholder .about_text p {
    color: #474747;
    font-size: clamp(.5rem, 1vw, .9rem);
  }
}

@media screen and (width <= 1367px) {
  body .contact_information .contact_container {
    width: 80%;
    line-height: 2;
  }
}

@media screen and (width <= 1025px) {
  body .container {
    width: 65%;
  }

  body .contact_information .contact_container {
    width: 100%;
    line-height: 2;
  }

  body .maps {
    height: 35vh;
  }
}

@media screen and (width <= 500px) {
  body .container, body .about_container {
    width: 90%;
  }

  body .container nav, body .about_container nav {
    border-bottom: none;
  }

  body .container nav .contact a, body .about_container nav .contact a, body .container nav .contact a:nth-child(3), body .about_container nav .contact a:nth-child(3), body .container nav .contact p, body .about_container nav .contact p {
    display: none;
  }

  body .container nav .contact .mobile_menu, body .about_container nav .contact .mobile_menu {
    display: block;
  }

  body .container .content_header, body .about_container .content_header {
    border-bottom: 1px solid #cecece;
    width: 100%;
    padding-bottom: 40px;
  }

  body .container .content_header .content p, body .about_container .content_header .content p {
    font-size: .95rem;
    line-height: 1.7;
  }

  body .container main, body .about_container main {
    width: 100%;
    margin-top: 60px;
    line-height: 1.7;
  }

  body .container main .pick_up_section, body .about_container main .pick_up_section {
    flex-direction: column;
  }

  body .container main .pick_up_section .flex, body .about_container main .pick_up_section .flex {
    width: 100%;
  }

  body .container main .pick_up_section .flex p, body .about_container main .pick_up_section .flex p {
    font-size: .9rem;
  }

  body .container main .pick_up_section .flex img, body .about_container main .pick_up_section .flex img {
    width: 100% !important;
  }

  body .container main .pick_up_section .flex .content h2, body .about_container main .pick_up_section .flex .content h2 {
    margin: 15px 0 5px;
    font-size: 1.3rem;
  }

  body .container main .pick_up_section .flex .content .icon, body .about_container main .pick_up_section .flex .content .icon {
    width: 9%;
    height: 4vh;
  }

  body .container main .pick_up_section .flex:nth-child(2), body .about_container main .pick_up_section .flex:nth-child(2) {
    width: 95%;
  }

  body .container main .delivery_section, body .about_container main .delivery_section {
    flex-direction: column-reverse;
    margin-top: 50px;
  }

  body .container main .delivery_section .flex, body .about_container main .delivery_section .flex {
    width: 100%;
  }

  body .container main .delivery_section .flex p, body .about_container main .delivery_section .flex p {
    font-size: .85rem;
  }

  body .container main .delivery_section .flex img, body .about_container main .delivery_section .flex img {
    width: 100% !important;
  }

  body .container main .delivery_section .flex .content h2, body .about_container main .delivery_section .flex .content h2 {
    margin: 15px 0 5px;
    font-size: 1.3rem;
  }

  body .container main .delivery_section .flex .content .icon, body .about_container main .delivery_section .flex .content .icon {
    width: 10%;
    height: 4vh;
  }

  body .container main .delivery_section .flex:first-child, body .about_container main .delivery_section .flex:first-child {
    width: 95%;
  }

  body .container .featured .featured_products, body .about_container .featured .featured_products {
    width: 100%;
    overflow: hidden;
  }

  body .container .featured .featured_products .featured_trey, body .about_container .featured .featured_products .featured_trey {
    width: 200%;
  }

  body .banner_text {
    padding-top: 0;
  }

  body .banner {
    text-align: center;
    height: max(600px, 60vh);
  }

  body .banner .container .banner_content {
    flex-direction: column-reverse;
  }

  body .banner .container .banner_content .banner_caption, body .banner .container .banner_content .banner_corousel {
    width: 100%;
  }

  body .banner .container .banner_content .banner_caption a, body .banner .container .banner_content .banner_corousel a {
    text-align: center;
    place-items: center;
    display: grid;
  }

  body .banner .container .banner_content .banner_caption a .about_us, body .banner .container .banner_content .banner_corousel a .about_us {
    padding: 10px 25px;
    font-size: .9rem;
  }

  body .banner .container .banner_content .banner_caption p, body .banner .container .banner_content .banner_corousel p {
    font-size: .9rem;
  }

  body .banner .container .banner_content .banner_corousel .corousel_trey {
    flex-grow: 0;
    height: max(260px, 30vh);
  }

  body .contact_information .contact_container .content_flexbox {
    flex-direction: column;
    width: 85%;
    margin: 0 auto;
  }

  body .contact_information .contact_container .content_flexbox .flex {
    width: 100%;
  }

  body .contact_information .contact_container .content_flexbox .flex .open_house_container {
    padding-bottom: 20px;
  }

  body .contact_information .contact_container .content_flexbox .flex .open_house_container .banana_container {
    display: none !important;
  }

  body .contact_information .contact_container .content_flexbox .flex .open_house_container div:nth-child(2) .content .open_hours p {
    font-size: clamp(.9rem, 50%, 1rem);
    font-weight: bold;
  }

  body .contact_information .contact_container .content_flexbox .flex .open_house_container div:nth-child(2) .content h5 {
    font-size: 1.2rem;
  }

  body .contact_information .contact_container .content_flexbox .flex .store_location {
    border: none;
    border-top: 1px solid #ffffff95;
    padding-top: 20px;
  }

  body .contact_information .contact_container .content_flexbox .flex .store_location .location_icon {
    width: 25px;
    margin: 0;
  }

  body .contact_information .contact_container .content_flexbox .flex .store_location .content {
    margin-left: 20px;
  }

  body .contact_information .contact_container .content_flexbox .flex .store_location .content h5 {
    font-size: 1.2rem;
  }

  body .contact_information .contact_container .content_flexbox .flex .contact_image {
    display: none;
  }

  body .copyright_section {
    height: 25vh;
  }

  body .copyright_section .copyright_container {
    height: inherit;
    flex-direction: column;
    justify-content: space-around;
  }

  body .copyright_section .copyright_container .contact_info {
    flex-direction: column;
    width: 60%;
  }

  body .copyright_section .copyright_container .contact_info button {
    border-radius: 30px;
    width: 100%;
    padding: 15px 20px;
    font-size: .9rem;
  }

  body .about_description {
    flex-direction: column;
    height: auto;
  }

  body .about_description .about_image, body .about_description .about_textholder {
    justify-content: center;
    width: 100%;
  }

  body .about_description .about_image .about_text, body .about_description .about_textholder .about_text {
    width: 95%;
  }

  body .about_description .about_image .image_holder {
    height: 30vh;
  }

  body .about_description2 {
    flex-direction: column;
    height: auto;
    margin-top: 0;
    display: block;
  }

  body .about_description2 .about_image .image_holder {
    height: 30vh;
  }

  body .about_description2 .about_image, body .about_description2 .about_textholder {
    justify-content: center;
    width: 100%;
  }

  body .about_description2 .about_image .about_text, body .about_description2 .about_textholder .about_text {
    width: 95%;
  }

  body .shop_gallery_container .shop_gallery {
    flex-wrap: wrap;
    justify-content: center;
  }

  body .shop_gallery_container .shop_gallery div {
    width: 45%;
  }

  body .gallery .image {
    width: 95%;
  }

  body .gallery .buttons {
    z-index: 2;
  }

  body .gallery .buttons div img {
    filter: invert() drop-shadow(0 0 2px #000);
  }
}
/*# sourceMappingURL=index.c6e38179.css.map */
