//Larger screens queries begins here...
@media screen and (min-width:1919px) {
    body {

        .container,.about_container {
            width: 57%;

            .content_header {
                width: 65%;

                .content {
                    p {
                        font-size: 1.1rem;
                    }
                }
            }

            main {
                width: 100%;

                .pick_up_section,
                .delivery_section {
                    .flex {
                        p {
                            font-size: 1.1rem;
                        }
                    }
                }
            }

            nav {
                .logo {
                    h4 {
                        font-size: 1rem;
                    }
                }
            }
        }



        .banner {
            height: max(350px,30vh);

            .container {
                .banner_content {
                    .banner_caption {
                        div {
                            p {
                                font-size: clamp(1rem, 0.8500rem + 0.6667vw, 1rem);
                            }

                            a {
                                .about_us {
                                    font-size: .9rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        .maps {
            height: 20vh;
        }

        .about_description {
            .about_textholder {
                .about_text {
                    p {
                        font-size: clamp(.5rem, 1vw, 1rem);
                        color: rgb(71, 71, 71);

                    }
                }
            }
        }

        .about_description2 {
            .about_textholder {
                .about_text {
                    p {
                        font-size: clamp(.5rem, 1vw, .9rem);
                        color: rgb(71, 71, 71);

                    }
                }
            }
        }
    }
}

//medium laptop screen styling begins here..
@media screen  and (max-width:1367px) {
    body{
        .contact_information{
            .contact_container{
                width: 80%;
                line-height: 2;
            }
        }
    }
}

//Tablet screens media queries begins here...
@media screen and (max-width:1025px) {
    body{

        .container{
            width: 65%;
        }

        .contact_information{
            .contact_container{
                width: 100%;
                line-height: 2;
            }
        }

        .maps{
            height: 35vh;
        }
    }
}

//Phone screens media queries begins here..
@media screen and (max-width:500px) {
    body{
        .container,.about_container{
            width: 90%;

            nav{
                border-bottom: none;
                

                .contact{
                    a{
                        &:nth-child(3){
                            display: none;
                        }
                        display: none;
                    }

                    p{
                        display: none;
                    }

                    .mobile_menu{
                        display: block;
                    }
                }
            }

            .content_header{
                width: 100%;
                border-bottom: 1px solid rgb(206, 206, 206);
                padding-bottom: 40px;

                .content{
                    p{
                        font-size: .95rem;
                        line-height: 1.7;
                    }
                }
            }

            main{
                margin-top: 60px;
                width: 100%;
                line-height: 1.7;

                .pick_up_section{
                    flex-direction: column;

                    .flex{
                        width: 100%;
                        
                        p{
                            font-size: .9rem;
                        }

                        img{
                            width: 100% !important;
                        }

                        .content{
                            h2{
                                margin: 15px 0 5px;
                                font-size: 1.3rem;
                            }
                            .icon{
                                width: 9%;
                                height: 4vh;
                            }
                        }

                        
                    }

                    .flex:nth-child(2){
                        width: 95%;
                    }
                }

                .delivery_section{
                    flex-direction: column-reverse;
                    margin-top: 50px;

                    .flex{
                        width: 100% ;
                        
                        p{
                            font-size: .85rem;
                        }

                        img{
                            width: 100% !important;
                        }

                        .content{
                            h2{
                                margin: 15px 0 5px;
                                font-size: 1.3rem;
                            }

                            .icon{
                                width: 10%;
                                height: 4vh;
                            }
                        }

                        
                    }

                    .flex:nth-child(1){
                        width: 95%;
                    }
                }
            }

            .featured{
                .featured_products{
                    width: 100%;
                    overflow: hidden;
                    .featured_trey{
                        width: 200%;

                    }
                }
            }
            
        }
        .banner_text{
            padding-top: 0;
        }

        .banner{
            height: max(600px,60vh);
            text-align: center;

            .container{
                .banner_content{
                    flex-direction: column-reverse;

                    .banner_caption,.banner_corousel{
                        width: 100%;

                        a{
                            text-align: center;
                            display: grid;
                            place-items: center;
                            
                            .about_us{
                                font-size: .9rem;
                                padding: 10px 25px;
                            }
                        }

                        p{
                            font-size: .9rem;
                        }
                    }

                    .banner_corousel{
                        .corousel_trey{
                            flex-grow: 0;
                            height: max(260px,30vh);
                        }
                       
                    }
                }

                
            }
        }

        .contact_information{
            .contact_container{
                .content_flexbox{
                    flex-direction: column;
                    width: 85%;
                    margin: 0 auto;

                    .flex{
                        width: 100%;
                        .open_house_container{
                            padding-bottom: 20px;
                            .banana_container{
                                display: none !important;
                            }

                            div:nth-child(2){
                                .content{
                                    .open_hours{
                                       
                                        p{
                                            font-size: clamp(.9rem,50%,1rem);
                                            font-weight: bold;
                                        }
                                    }

                                    h5{
                                        font-size: 1.2rem;
                                    }
                                }
                            }
                        }

                        .store_location{
                            border: none;
                            padding-top: 20px;
                            border-top: solid 1px rgba(255, 255, 255, 0.586);

                            .location_icon{
                                width: 25px;
                                margin: 0;
                            }

                            .content{
                                margin-left: 20px;

                                h5{
                                    font-size: 1.2rem;
                                }
                            }
                        }

                        .contact_image{
                            display: none;
                        }
                    }
                }
            }
        }

        .copyright_section{
            height: 25vh;
            

            .copyright_container{
                flex-direction: column;
                justify-content: space-around;
                height: inherit;

                .contact_info{
                    width: 60%;
                    flex-direction: column;
                    button{
                        width: 100%;
                        padding: 15px 20px;
                        border-radius: 30px;
                        font-size: .9rem;
                    }
                }
            }
        }

        .about_description{
            flex-direction: column;
            height: auto;
            .about_image, .about_textholder{
                justify-content: center;
                width: 100%;

                .about_text{
                    width: 95%;
                }
            }
            .about_image{
                .image_holder{
                    height: 30vh;
                }
                
            }
        }

        .about_description2{
            display: block;
            flex-direction: column;
            margin-top: 0;
            height: auto;

            .about_image{
                .image_holder{
                    height: 30vh;
                }
                
            }

            .about_image, .about_textholder{
                width: 100%;
                justify-content: center;

                .about_text{
                    width: 95%;
                }
            }
        }

        .shop_gallery_container{
            .shop_gallery{
                flex-wrap: wrap;
                justify-content: center;

                div{
                    width: 45%;
                }
            }
        }

        .gallery{
            .image{
                width: 95%;
            }

            .buttons{
                z-index: 2;

                div{
                    img{
                        filter: invert(100%)  drop-shadow(0 0 2px black);
                        
                    }
                }
            }
        }
    }
}